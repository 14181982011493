const data = {
  participation_id: null,
  download_url: null,
  project_id: null,
  participations: 5,
  registration_type: 'person',
  contact: {
    name: '',
    gender: '',
    birth_date: '',
    birth_place: '',
    function: '',
    nationality: '',
    phone: '',
    email: '',
    capital_origin: '',
    legitimation: '',
    prison_sentence: false,
    politically_exposed: false,
    address: '',
    diff_mailing_address: false,
  },
  account: null,
  bank: {
    iban: '',
    bic: '',
    accountholder: '',
    tax_id: '',
  },
};

export default data;
