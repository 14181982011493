export class ParticipationAPI {
  constructor(Client, DownloadClient) {
    this.Client = Client;
    this.DownloadClient = DownloadClient;
  }

  download = _url => {
    return this.DownloadClient.get(_url).then(response => response);
  };

  all = () => {
    return this.Client.get('/participations').then(response => response.data);
  };

  save = (_project_id, _portal_user_id, _account_id) => {
    return this.Client.post('/participations', {
      project_id: _project_id,
      portal_user_id: _portal_user_id,
      account_id: _account_id,
    }).then(response => response.data);
  };
}

export default ParticipationAPI;
