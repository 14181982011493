// Import => Models
import Project from '@models/project.model.js';

const _attributes = [
  'minimal_participation_amount',
  'preferential_return',
  'roi_expected',
  'equity_growth_expected',
  'participations_count',
  // 'ended_at',
  'enrollment_deadline',
  'equity_growth_amount',
  'equity_target',
  'equity_current',
  'forecast_amount',
  // 'forecast_date',
  'completed_at',
  'gross_total_return',
  'gross_return_roi_per_year',
  'roi_realised',
  'participations_amount',
  'participations_total_amount',
  'benefits_total_amount',
];

export class ProjectsAPI {
  constructor(Client, Store) {
    this.Client = Client;
    this.Store = Store;

    this.properties = {
      application: true,
      attributes: _attributes,
      completedAttributes: _attributes,
      contacts: true,
      description: true,
      documents: true,
      images: true,
      keyfigures: true,
      kpis: true,
      status: true,
      transactions: true,
      updates: true,
    };
  }

  all = () => {
    return this.Client.get('/projects').then(response => {
      if (!response || !response.data) return false;
      let { data } = response.data;

      return data.map(project => new Project(project, this.properties, this.Store));
    });
  };

  single = (id, update) => {
    let update_draft = '';
    if (update) {
      update_draft = `?update_draft=${update}`;
    }

    return this.Client.get(`/projects/${id}${update_draft}`).then(async response => {
      if (!response || !response.data) return false;
      let { data } = response.data;

      const kpis = await this.kpis(data.id);
      const updates = await this.updates(data.id, update);

      return new Project(
        {
          ...data,
          project_kpis: kpis[data.status] ? kpis[data.status] : [],
          some_updates: !updates ? [] : updates,
        },
        this.properties,
        this.Store
      );
    });
  };

  updates = (id, update) => {
    let draft = '';
    if (update) {
      draft = `?draft=${update}`;
    }
    return this.Client.get(`/projects/${id}/updates${draft}`).then(response => {
      return response.data.data;
    });
  };

  kpis = id => {
    return this.Client.get(`/projects/${id}/kpis`).then(response => {
      return response.data;
    });
  };

  status = status => {
    return this.Client.get(`/projects?filter[status]=${status}`).then(response => {
      if (!response || !response.data) return false;
      let { data } = response.data;

      return data.map(project => new Project(project, this.properties, this.Store));
    });
  };

  fases = () => {
    return this.Client.get('/projects/fases').then(response => response.data.data);
  };
}

export default ProjectsAPI;
