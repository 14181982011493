/* eslint-disable import/first */
// Imports => React
import React from 'react';
import loadable from '@loadable/component';
import { inject, observer } from 'mobx-react';
import { Switch, withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import clsx from 'clsx';

import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import TagManager from 'react-gtm-module';

// Imports => SCSS
import '@styles/index.scss';
import 'react-responsive-carousel/lib/styles/carousel.css'; // requires a loader

// Imports => Assets
const AcAFMWarningEN = loadable(() =>
  import('@assets/images/afm-warning-2x-en.jpg')
);
const AcAFMWarningNL = loadable(() =>
  import('@assets/images/afm-warning-2x-nl.jpg')
);
const AcLogoFullWhiteSVG = loadable(() =>
  import('@assets/images/realiance-logo-full-white-1x.svg')
);

// Imports => Views
const AcAccount = loadable(() => import('@views/ac-account/ac-account.web.js'));
const AcParticipation = loadable(() =>
  import('@views/ac-participation/ac-participation.web.js')
);
const AcImpersonate = loadable(() =>
  import('@views/ac-impersonate/ac-impersonate.web.js')
);
const AcAuthenticate = loadable(() =>
  import('@views/ac-authenticate/ac-authenticate.web.js')
);
const AcDashboard = loadable(() =>
  import('@views/ac-dashboard/ac-dashboard.web.js')
);
const AcNewPassword = loadable(() =>
  import('@views/ac-new-password/ac-new-password.web.js')
);
const AcNewsArchive = loadable(() =>
  import('@views/ac-news-archive/ac-news-archive.web.js')
);
const AcNewsArticle = loadable(() =>
  import('@views/ac-news-article/ac-news-article.web.js')
);
const AcParticipationsOverview = loadable(() =>
  import('@views/ac-participations-overview/ac-participations-overview.web.js')
);
const AcPortfolio = loadable(() =>
  import('@views/ac-portfolio/ac-portfolio.web.js')
);
const AcProject = loadable(() => import('@views/ac-project/ac-project.web.js'));
const AcRegister = loadable(() =>
  import('@views/ac-register/ac-register.web.js')
);
const AcFaq = loadable(() => import('@views/ac-faq/ac-faq.web.js'));
const AcDocuments = loadable(() =>
  import('@views/ac-documents/ac-documents.web.js')
);

// Imports => Molecules
const AcPrivateRoute = loadable(() =>
  import('@molecules/ac-private-route/ac-private-route.web.js')
);
const AcToasterHoc = loadable(() =>
  import('@molecules/ac-toaster-hoc/ac-toaster-hoc.web.js')
);

// Imports => Components
const AcHeader = loadable(() =>
  import('@components/ac-header/ac-header.web.js')
);
const AcLightBox = loadable(() =>
  import('@components/ac-light-box/ac-light-box.web.js')
);
const AcPartnerFooter = loadable(() =>
  import('@components/ac-partner-footer/ac-partner-footer.web.js')
);
const RouteChangeTracker = loadable(() =>
  import('@components/route-change-tracker/route-change-tracker.js')
);

// Imports => Atoms
const AcSection = loadable(() => import('@atoms/ac-section/ac-section.web.js'));
const AcImage = loadable(() => import('@atoms/ac-image/ac-image.web.js'));
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web.js'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web.js'));

const _CLASSES = {
  MAIN: 'ac-app',
  ROOT: 'ac-root',
  TRANSITIONGROUP: 'ac-route__transition-group',
  TRANSITIONGROUPHIDDEN: 'ac-route__transition-group--hidden',
  PAGETRANSITION: 'ac-route__transition',
  ROUTESECTION: 'ac-route__section',
  MASK: {
    MAIN: 'ac-route__transition-mask',
    ENTER: 'ac-route__transition-mask--animating-enter',
    EXIT: 'ac-route__transition-mask--animating-exit',
  },
  LOADER: 'ac-route__loader',
};

let timeout = {
  enter: false,
  entered: false,
  exit: false,
  exited: false,
};

const tagManagerArgs = {
  gtmId: 'GTM-5SQMRST',
};

TagManager.initialize(tagManagerArgs);

@inject('store')
@observer
class App extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;
    this.route = props.location.pathname;

    this.getCopyrightContent = this.getCopyrightContent.bind(this);

    this.state = {
      onEndListener: false,
      animation: {
        mountOnEnter: true,
        unmountOnExit: true,
        appear: true,
        enter: false,
        exit: false,
        duration: 1300,
        delay: 1300,
      },
      afm: {
        en: AcAFMWarningEN,
        nl: AcAFMWarningNL,
      },
    };

    this.init = this.init.bind(this);
    this.hideNavigation = this.hideNavigation.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);

    this.handleEnterState = this.handleEnterState.bind(this);
    this.handleEnteredState = this.handleEnteredState.bind(this);
    this.handleExitState = this.handleExitState.bind(this);
    this.handleExitedState = this.handleExitedState.bind(this);
  }

  componentDidMount() {
    if (this.store.auth.isAuthorized) this.init();
  }

  componentDidUpdate(nextProps, prevProps) {
    if (this.props.location.pathname !== this.route) {
      this.route = this.props.location.pathname;
      this.handleRouteChanged();
    }
  }

  async init() {
    if (this.store.auth.isAuthorized) {
      await this.store.auth.whoami();
      await this.store.api.kpis.all();
    }
  }

  handleRouteChanged(event) {
    this.hideNavigation();
    this.closeLightbox();
    this.init();
  }

  handleEnterState() {
    if (timeout.entered) clearTimeout(timeout.entered);
    if (timeout.exit) clearTimeout(timeout.exit);
    if (timeout.exited) clearTimeout(timeout.exited);

    this.setState({
      animation: {
        ...this.state.animation,
        appear: false,
        enter: true,
        exit: false,
      },
    });
  }

  handleEnteredState() {
    timeout.entered = setTimeout(() => {
      this.setState({
        animation: {
          ...this.state.animation,
          appear: false,
          enter: false,
          exit: false,
        },
      });
    }, this.state.animation.delay);
  }

  handleExitState(node, done) {
    if (timeout.entered) clearTimeout(timeout.entered);
    if (timeout.exited) clearTimeout(timeout.exited);
    timeout.exit = setTimeout(() => {
      this.setState({
        animation: {
          ...this.state.animation,
          appear: false,
          enter: false,
          exit: true,
        },
      });

      if (done) done();
    }, this.state.animation.delay);
  }

  handleExitedState() {
    if (timeout.exit) clearTimeout(timeout.exit);
    timeout.exited = setTimeout(() => {
      this.setState({
        animation: {
          ...this.state.animation,
          appear: false,
          enter: false,
          exit: false,
        },
      });
    }, this.state.animation.delay);
  }

  hideNavigation(event) {
    this.store.ui.setState('navigation', 'visible', false);
    this.store.ui.setState('user_navigation', 'visible', false);
  }

  closeLightbox() {
    this.store.ui.closeLightbox();
  }

  getCopyrightContent() {
    const today = new Date();
    const year = today.getFullYear();

    let result = year;

    return result;
  }

  getLoaderClassNames() {
    return clsx(_CLASSES.LOADER);
  }

  getMaskClassNames() {
    const { animation } = this.state;

    return clsx([
      _CLASSES.MASK.MAIN,
      animation && (animation.enter || animation.appear) && _CLASSES.MASK.ENTER,
      animation && animation.exit && _CLASSES.MASK.EXIT,
    ]);
  }

  getTransitionClassNames() {
    return clsx(_CLASSES.PAGETRANSITION);
  }

  getTransitionGroupClassNames() {
    const { ui } = this.store;

    const hidden = ui.navigation.visible;

    return clsx(
      _CLASSES.TRANSITIONGROUP,
      hidden && _CLASSES.TRANSITIONGROUPHIDDEN
    );
  }

  getRouteSectionClassNames() {
    return clsx(_CLASSES.ROUTESECTION);
  }

  getRootClassNames() {
    return clsx(_CLASSES.ROOT);
  }

  render() {
    const { location, store } = this.props;
    const { animation } = this.state;

    return (
      <div className={this.getRootClassNames()}>
        <RouteChangeTracker />
        <AcHeader theme={store.auth.isAuthorized ? '' : 'transparent'} />
        <TransitionGroup
          className={this.getTransitionGroupClassNames()}
          onClick={this.hideNavigation}
        >
          <CSSTransition
            key={location.key}
            timeout={animation.duration}
            onEnter={this.handleEnterState}
            onEntered={this.handleEnteredState}
            onExit={this.handleExitState}
            onExited={this.handleExitedState}
            mountOnEnter={animation.mountOnEnter}
            unmountOnExit={animation.unmountOnExit}
            appear={animation.appear}
            classNames={_CLASSES.PAGETRANSITION}
          >
            <section
              className={this.getRouteSectionClassNames()}
              id={'ac-scroller'}
            >
              <Switch location={location}>
                <AcPrivateRoute
                  path={'/login'}
                  name={'AcLogin'}
                  component={AcAuthenticate}
                  forbidden={false}
                  exact
                />
                <AcPrivateRoute
                  path={'/impersonate'}
                  name={'AcImpersonate'}
                  component={AcImpersonate}
                  forbidden={false}
                  exact
                />
                <AcPrivateRoute
                  path={'/register/:token'}
                  name={'AcRegister'}
                  component={AcRegister}
                  forbidden={false}
                  exact
                />
                <AcPrivateRoute
                  path={'/password/reset/:token'}
                  name={'AcNewPassword'}
                  component={AcNewPassword}
                  forbidden={false}
                  exact
                />
                <AcPrivateRoute
                  path={'/'}
                  name={'AcDashboard'}
                  component={AcDashboard}
                  forbidden={true}
                  exact
                />
                <AcPrivateRoute
                  path={'/portfolio'}
                  name={'AcPortfolio'}
                  component={AcPortfolio}
                  forbidden={true}
                  exact
                />
                <AcPrivateRoute
                  path={'/news'}
                  name={'AcNewsArchive'}
                  component={AcNewsArchive}
                  forbidden={true}
                  exact
                />
                <AcPrivateRoute
                  path={'/faq'}
                  name={'AcFaq'}
                  component={AcFaq}
                  forbidden={true}
                  exact
                />
                <AcPrivateRoute
                  path={'/documents'}
                  name={'AcDocuments'}
                  component={AcDocuments}
                  forbidden={true}
                  exact
                />
                <AcPrivateRoute
                  path={'/news/:id'}
                  name={'AcNewsArticle'}
                  component={AcNewsArticle}
                  forbidden={true}
                  exact
                />
                <AcPrivateRoute
                  path={'/participations'}
                  name={'AcParticipationsOverview'}
                  component={AcParticipationsOverview}
                  forbidden={true}
                  exact
                />
                <AcPrivateRoute
                  path={'/participate/:projectid'}
                  name={'AcParticipation'}
                  component={AcParticipation}
                  forbidden={true}
                  exact
                />
                <AcPrivateRoute
                  path={'/account'}
                  name={'AcAccount'}
                  component={AcAccount}
                  forbidden={true}
                  exact
                />
                <AcPrivateRoute
                  path={'/project/:projectid'}
                  name={'AcProject'}
                  component={AcProject}
                  forbidden={true}
                  exact
                />
                <AcPrivateRoute component={AcDashboard} forbidden={true} />
              </Switch>

              {store.auth.isAuthorized && (
                <>
                  <AcSection theme={'pitch'} className={'ac-footer'}>
                    {/*{store.auth.isAuthorized &&*/}
                    {/*  store.auth.user &&*/}
                    {/*  store.auth.user.branding &&*/}
                    {/*  store.auth.user.branding.aureus && <AcPartnerFooter />}*/}
                    <AcContainer>
                      {location.pathname.match(/participate/) && (
                        <AcRow>
                          <AcColumn xs={12} sm={{ size: 10, offset: 1 }}>
                            <AcImage
                              type={'image'}
                              source={
                                this.state.afm[this.store.settings.language]
                              }
                              size={{
                                width: 'auto',
                                height: 'auto',
                              }}
                              className={'ac-footer__afm-warning'}
                              wrpClassName={'ac-footer__afm-warning-wrp'}
                            />
                          </AcColumn>
                        </AcRow>
                      )}

                      <AcRow>
                        <AcColumn xs={12} sm={{ size: 10, offset: 1 }}>
                          <AcLogoFullWhiteSVG
                            className={'ac-footer__logo h-margin-y-40'}
                          />
                        </AcColumn>
                      </AcRow>

                      <AcRow>
                        <AcColumn>
                          <div className={'ac-footer__copyright'}>
                            <AcIcon
                              icon={'copyright'}
                              className={'ac-footer__copyright__icon'}
                            />
                            {this.getCopyrightContent()}
                          </div>
                        </AcColumn>
                      </AcRow>
                    </AcContainer>
                  </AcSection>
                </>
              )}
            </section>
          </CSSTransition>
        </TransitionGroup>

        {store.ui.lightbox &&
          typeof store.ui.lightbox.target !== 'undefined' && (
            <AcLightBox
              items={store.ui.lightbox.collection}
              target={store.ui.lightbox.target}
              visible={store.ui.lightbox.visible}
              closeCallback={this.closeLightbox}
            />
          )}

        <span className={this.getMaskClassNames()}>
          <AcLoader
            theme={'white'}
            loading={this.state.animation.enter}
            className={this.getLoaderClassNames()}
          />
        </span>

        <AcToasterHoc />
      </div>
    );
  }
}

export default withNamespaces()(withRouter(App));
