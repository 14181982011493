// Imports => Vendor
import axios from 'axios';

// Imports => API
import AuthAPI from '@api/auth.api';
import LocaleAPI from '@api/locale.api';
import KPISApi from '@api/kpis.api';
import NewsAPI from '@api/news.api';
import ParticipationAPI from '@api/participation.api.js';
import ProfileApi from '@api/profile.api';
import ProjectsAPI from '@api/projects.api';
import FaqAPI from '@api/faq.api';
import DocumentsAPI from '@api/documents.api';
import ProjectIntroductionsAPI from '@api/project-introductions.api';
import OverviewProjectUpdatesAPI from '@api/overview-project-updates.api';

const onUploadProgress = event => {
  // console.group('[Axios] => fn.onUploadProgress');
  // console.log('Event: ', event);
  // console.groupEnd();
};

const onDownloadProgress = event => {
  // console.group('[Axios] => fn.onDownloadProgress');
  // console.log('Event: ', event);
  // console.groupEnd();
};

export class API {
  constructor(config, Store) {
    this.Store = Store;

    const Client = axios.create({
      ...config.api,
      onUploadProgress,
      onDownloadProgress,
    });

    const DownloadClient = axios.create({
      ...config.download,
      onUploadProgress,
      onDownloadProgress,
    });

    this.auth = new AuthAPI(Client);

    this.locale = new LocaleAPI(Client);

    this.kpis = new KPISApi(Client);

    this.participation = new ParticipationAPI(Client, DownloadClient);

    this.profile = new ProfileApi(Client);

    this.projects = new ProjectsAPI(Client, this.Store);

    this.news = new NewsAPI(Client);

    this.faq = new FaqAPI(Client);

    this.documents = new DocumentsAPI(Client, DownloadClient);

    this.project_introductions = new ProjectIntroductionsAPI(Client);

    this.overview_project_updates = new OverviewProjectUpdatesAPI(Client);
  }
}

export default API;
