export class AuthAPI {
  constructor(Client) {
    this.Client = Client;
  }

  whoami = () => {
    return this.Client.get(`/profile/who-am-i`).then(response => response.data);
  };

  forgot = _username => {
    return this.Client.post('/auth/forgot', {
      username: _username,
    }).then(response => response.data);
  };

  reset = (_password, _reset_token) => {
    return this.Client.post('/auth/reset', {
      password: _password,
      reset_token: _reset_token,
    }).then(response => response.data);
  };

  login = (_username, _password) => {
    return this.Client.post('/auth/login', {
      username: _username,
      password: _password,
    }).then(response => response.data);
  };

  impersonate = (user_id, signature, expires) => {
    return this.Client.get(`/auth/impersonate/${user_id}`, {
      params: {
        signature,
        expires,
      },
    }).then(response => response.data);
  };

  register = (_username, _password, _invite_token) => {
    return this.Client.post(`/auth/register`, {
      username: _username,
      password: _password,
      invite_token: _invite_token,
    }).then(response => response.data);
  };

  invitetoken = () => {
    return this.Client.post(`/auth/invite`).then(response => response.data);
  };

  logout = () => {
    return this.Client.get(`/auth/logout`).then(response => response.data);
  };
}

export default AuthAPI;
