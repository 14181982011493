let data = { nl: '', en: '' };

data.nl += '<p>';
data.nl +=
  '<span class="h-text--bullet h-text--semibold h-text--size-regular">1</span> Download het document';
data.nl += '</p>';

data.en += '<p>';
data.en +=
  '<span class="h-text--bullet h-text--semibold h-text--size-regular">1</span> Download the document';
data.en += '</p>';

export default data;
