let data = { nl: '', en: '' };

data.nl += '<p class="h-text--regular">';
data.nl += '<i class="ac-icon ac-icon--email"></i> <strong>Post</strong>';
data.nl += '<br>';
data.nl += '<strong>REALIANCE</strong>';
data.nl += '<br>';
data.nl += 'Skoon 78';
data.nl += '<br>';
data.nl += '1511 HV,  Oostzaan';
data.nl += '<br>';
data.nl += 'NEDERLAND';
data.nl += '</p>';

data.en += '<p class="h-text--regular">';
data.en += '<i class="ac-icon ac-icon--email"></i> <strong>Post</strong>';
data.en += '<br>';
data.en += '<strong>REALIANCE</strong>';
data.en += '<br>';
data.en += 'Skoon 78';
data.en += '<br>';
data.en += '1511 HV,  Oostzaan';
data.en += '<br>';
data.en += 'NEDERLAND';
data.en += '</p>';

export default data;
