let data = { nl: '', en: '' };

data.nl += '<p class="h-text--regular">';
data.nl += '<i class="ac-icon ac-icon--mail-ru"></i> <strong>E-mail</strong>';
data.nl += '<br>';
data.nl += '<a href="mailto: invest@realiance.nl">invest@realiance.nl</a>';
data.nl += '</p>';

data.en += '<p class="h-text--regular">';
data.en += '<i class="ac-icon ac-icon--mail-ru"></i> <strong>Email</strong>';
data.en += '<br>';
data.en += '<a href="mailto: invest@realiance.nl">invest@realiance.nl</a>';
data.en += '</p>';

export default data;
