export class ProjectIntroductionsAPI {
  constructor(Client) {
    this.Client = Client;
  }

  all = () => {
    return this.Client.get('/project-introductions').then(response => {
      if (!response || !response.data) return false;
      return response.data;
    });
  };
}

export default ProjectIntroductionsAPI;
