let data = { nl: '', en: '' };

data.nl += '<p>';
data.nl +=
  '<span class="h-text--bullet h-text--semibold h-text--size-regular">2</span> Controleer alle gegevens en vul aan indien nodig';
data.nl += '</p>';

data.nl += '<p>';
data.nl +=
  '<span class="h-text--bullet h-text--semibold h-text--size-regular">3</span> Onderteken het document en stuur het op:';
data.nl += '</p>';

data.en += '<p>';
data.en +=
  '<span class="h-text--bullet h-text--semibold h-text--size-regular">2</span> Verify all fields and complete if neccessary';
data.en += '</p>';

data.en += '<p>';
data.en +=
  '<span class="h-text--bullet h-text--semibold h-text--size-regular">3</span> Sign the document and send it to:';
data.en += '</p>';

export default data;
