let data = '';

data += '<ol>';
data +=
  '<li>Dat er kennis is genomen van de volledige inhoud van het verstrekte investment memorandum van Hampton Point en dat akkoord wordt gegaan met de inhoud daarvan alsmede met de communicatie hieromtrent.</li>';

data +=
  '<li>Dat men ervan op de hoogte is dat de propositie is vrijgesteld van prospectusplicht en de initiatiefnemer c.q.beheerder niet vergunningplichtig is op basis van de Wet op financieel toezicht (Wft).</li>';

data +=
  '<li>Er mee in te stemmen dat REALIANCE USA B.V. of een daaraan gelieerde entiteit optreedt als Special Limited Partner van Hampton Point Apartments LP (verder ook te noemen LP) en in deze hoedanigheid de LP beheert onder meer in verband met het verkrijgen, het vervreemden en het bezwaren van de activa van de LP, alsmede het communiceren en het rapporteren aan de deelnemers.</li>';

data +=
  '<li>Dat het verstrekte vermogen (persoonlijk) eigendom is en dat op het moment dat de belegging wordt gedaan ondergetekende geautoriseerd is om over deze middelen te beschikken.</li>';

data +=
  '<li>Bekend te zijn met het feit dat het deelnamebedrag dient te worden overgemaakt op een separaat geopende rekening ten behoeve van de belegging in het project Hampton Point. Informatie volgt in de betalingsinstructie die u wordt toegezonden.</li>';

data +=
  '<li>Het inschrijfformulier te laten voorzien van een legalisatie van de handtekening van de ondergetekende door een notaris, indien ondergetekende nog niet eerder is geïdentificeerd door notariskantoor Albers en Van Tienen te Amsterdam of een van de Managing Partners van REALIANCE.</li>';

data += '<li>';
data +=
  'Akkoord te gaan dat de definitieve deelname pas kan plaatsvinden nadat:';
data += '<ul>';
data +=
  '<li>Het volledig ingevulde en (bevoegd) ondertekende originele inschrijfformulier met bijbehorende kopie van de (nog minimaal 6 maanden geldige) legitimatie van de ondertekenaar en zo nodig een recent origineel uittreksel uit het handelsregister van de Kamer van Koophandel (maximaal 6 maanden oud) is ontvangen door REALIANCE;</li>';

data += '<li>Het relatieonderzoek door REALIANCE positief is afgerond;</li>';

data +=
  '<li>Het totale deelnamebedrag door de LP is ontvangen en deze ontvangst door de LP is bevestigd;</li>';

data +=
  '<li>De Limited Partnership Agreement van de LP, welke tevens dient als deelnamebevestiging, ondertekend is ontvangen.</li>';
data += '</ul>';
data += '</li>';
data += '</ol>';

export default data;
