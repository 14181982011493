export class ProfileAPI {
  constructor(Client) {
    this.Client = Client;

    this.account.get = this.account.get.bind(this);
    this.account.set = this.account.set.bind(this);
  }

  whoami = () => {
    return this.Client.get('/profile/who-am-i').then(response => response.data);
  };

  account = {
    get: () => {
      return this.Client.get(`/profile/account`).then(response => response.data);
    },
    set: _account_id => {
      return this.Client.post(`/profile/account`, {
        account_id: _account_id,
      }).then(response => response.data);
    },
  };
}

export default ProfileAPI;
