export class DocumentsAPI {
  constructor(Client, DownloadClient) {
    this.Client = Client;
    this.DownloadClient = DownloadClient;
  }

  all = () => {
    return this.Client.get('/documents').then(response => {
      if (!response || !response.data) return false;
      return response.data;
    });
  };

  single = id => {
    return this.DownloadClient.get(`/documents/${id}`).then(response => response);
  };
}

export default DocumentsAPI;
