export class LocaleAPI {
  constructor(Client) {
    this.Client = Client;
  }

  get = () => {
    return this.Client.get('/profile/language').then(response => response.data);
  };

  set = language => {
    return this.Client.post(`/profile/language`, {
      language: language,
    }).then(response => response.data);
  };
}

export default LocaleAPI;
